
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

/* .column-css-name {
  font-size: 0.7rem !important;
  font-weight: 500 !important;
  letter-spacing: 1px;
}

.table-css-row {
  font-size: 0.1rem !important;
  font-weight: 500 !important;
  letter-spacing: 1px;
} */

.card-background {
  color: aliceblue !important;
  background-color: rgb(68,68,68) !important;
}

.center-login {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important; 
}

.ant-table-tbody td{
  padding: 5px 16px !important;
}